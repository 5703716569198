import React from 'react';
import loadable from '@loadable/component';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faLightbulbDollar } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const StartBenefits = loadable(() => import('components/subscription-benefits/start-up'));
const BaseSub = loadable(() => import('components/landing-prices/base-sub'));
const Divider = loadable(() => import('@material-ui/core/Divider'));

const model = {
  cost: '£1:49',
  name: 'Start',
};

const StartSubscription = ({
  looking = false,
  onExpand = () => {},
  expanded = false,
  single = false,
  landing = false,
}) => {
  const styles = useStyles();

  const { name, cost } = model;

  return (
    <>
      <span id={name}/>
    <BaseSub
      name={name}
      single={single}
      level="1"
      title={`${name} ${cost} month`}
      smTitle={`${name} ${cost}p/m`}
      subheader="100 active cards"
      smSubTitle="100 active cards"
      avatar={
          <FontAwesomeIcon icon={faLightbulbDollar} className={styles.startAvatar}/>
        }
      looking={looking}
      onExpand={() => {
        setTimeout(async () => {
          const buildDataLayer = await import('data-layer');
          const dataLayer = buildDataLayer.default();
          dataLayer.trackLanding({
            descriptor: 'toggle_subcription_information',
            label: name,
          });
          onExpand();
        }, 100);
      }}
      expanded={expanded}
      content={
      <>
        <div className={styles.dividerMargin}>
          <Divider />
        </div>
        <StartBenefits/>
        </>
      }
      landing={landing}
    />
    </>
  );
};

StartSubscription.propTypes = {
  looking: bool,
  onExpand: func,
  expanded: bool,
  single: bool,
  landing: bool,
};

export default StartSubscription;
